import dayjs from "dayjs";
import React, { useState, useEffect } from "react";

const Countdown = ({ date, color }) => {
  const [days, setDays] = useState(null);
  const [hours, setHours] = useState(null);
  const [minutes, setMinutes] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const diffMins = dayjs(date).diff(dayjs(), "minutes");
      const days = Math.floor(diffMins / (24 * 60));
      setDays(days);
      const rest = diffMins - days * (24 * 60);
      const hours = Math.floor(rest / 60);
      setHours(hours);
      setMinutes(rest - hours * 60);
    }, 1000);
    return () => clearInterval(interval);
  }, [date]);

  return (
    <>
      {days >= 0 && (
        <div className="countdown" style={{ "--color": color }}>
          <div className="countdown__title">L'OFFERTA SCADE TRA:</div>
          <div className="d-flex">
            <div className="countdown__block">
              <div className="countdown__value">{days}</div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>:</div>
            <div className="countdown__block">
              <div className="countdown__value">{hours}</div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>:</div>
            <div className="countdown__block">
              <div className="countdown__value">{minutes}</div>
            </div>
          </div>
          <div className="d-flex">
            <div className="countdown__label" style={{ flex: "auto" }}>
              {days === 1 ? "GIORNO" : "GIORNI"}
            </div>
            <div className="countdown__label" style={{ flex: "auto" }}>
              {hours === 1 ? "ORA" : "ORE"}
            </div>
            <div className="countdown__label" style={{ flex: "auto" }}>
              {minutes === 1 ? "MINUTO" : "MINUTI"}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Countdown;
