import React from "react";

const ArrowScroller = ({ scroll }) => {
  return (
    <button
      className="arrow-scroller__container d-none d-lg-inline-block"
      aria-label="Scroll down"
      onClick={(event) => {
        event.stopPropagation();
        window.scrollTo(scroll);
      }}
    >
      <div className="arrow-scroller__arrow arrow-scroller__arrow--first">&nbsp;</div>
      <div className="arrow-scroller__arrow arrow-scroller__arrow--second">&nbsp;</div>
    </button>
  );
};

export default ArrowScroller;
