let netreviewsScriptInserted = false;

const insertNetreviewsScript = () => {
  // console.log('Appending reevoo script: ', accountCode || 'CDD-IT');
  const netreviewsScript = document.createElement("script");
  netreviewsScript.id = "netreviews-script";
  netreviewsScript.setAttribute(
    "src",
    `https://cl.avis-verifies.com/it/cache/5/a/8/5a8324a4-7fa4-4323-ba7d-15304d17b78b/AWS/PRODUCT_API/tag.min.js`
  );
  netreviewsScript.setAttribute("async", "");
  netreviewsScript.setAttribute("defer", "");
  document.head.appendChild(netreviewsScript);
};

export const eventuallyInsertNetreviewsScript = () => {
  if (!netreviewsScriptInserted) {
    netreviewsScriptInserted = true;
    insertNetreviewsScript();
  }
};
