import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { getImage, withArtDirection } from "gatsby-plugin-image";
import classNames from "classnames";
import { Link } from "../link";
import SlideHeaderContent from "../elements/slide-header-content";
import withPreview from "../../utility/with-preview";
import { isFlusso, getProductName } from "../../utility/config-flussi-switch-in";
import Cookies from "js-cookie";

const SlideHeader = ({ data }) => {
  const {
    model,
    slideXl,
    slide,
    slideTablet,
    slideMobile,
    titolo,
    descrizione,
    /*textShort,**/
    showProductStars,
    /*showText,*/
    cta,
    arrowScroller,
    introSb,
    cdownDate,
    titoloCard,
    descrizioneHtml,
    sottotitoloDescr,
    /*Gestione CTA Doppia*/
    ctaNew,
    isAbTest,
  } = data;
  const modello = model?.value?.[0] || "SlideHeader_mobileView";

  /* Gesione A/B test */
  var abTest = "";
  //console.log(isAbTest?.value);
  isAbTest?.value ? (abTest = " abtest") : (abTest = "");
  /**/

  const headerImg =
    modello === "SlideHeader"
      ? getImage(slide?.node)
      : withArtDirection(
          getImage(slideXl?.node || slide?.node),
          [
            slideMobile?.node && {
              media: "(max-width: 768px)",
              image: getImage(slideMobile.node),
            },
            slideTablet?.node && {
              media: "(max-width: 992px)",
              image: getImage(slideTablet.node),
            },
            slide?.node && {
              media: "(max-width: 1200px)",
              image: getImage(slide.node),
            },
          ].filter(Boolean)
        );

  const startFlusso = (productName) => {
    const fluxEvent = new CustomEvent("start-flusso-switch-in", {
      detail: {
        productName,
      },
    });
    document.dispatchEvent(fluxEvent);
  };

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <section
      className={classNames("slide-header position-relative", {
        "slide-header--modello-slideheader": ["SlideHeader", "SlideHeaderNew"].includes(modello),
        "slide-header--modello-slideheadernew": modello === "SlideHeaderNew",
        "slide-header--with-card": titoloCard?.value,
      })}
    >
      <SlideHeaderContent
        headerImg={headerImg}
        headerRatio={slideMobile?.node?.probeInfo?.aspectRatio}
        title={titolo?.value}
        wrapped={true}
        flex={true}
        description={descrizione?.value}
        productStars={{
          show: modello === "SlideHeader_mobileView" ? showProductStars?.value : false,
          prodId:
            modello === "SlideHeader_mobileView"
              ? showProductStars?.content?.dataProductId?.value
              : "",
        }}
        cta={cta?.content?.ctaLink?.value && cta?.content?.ctaLink?.value !== "#" && cta}
        ctaFlussoAction={startFlusso}
        arrowScroller={{
          value: arrowScroller?.value,
          scroll: { top: 400, left: 0, behavior: "smooth" },
        }}
        countdown={cdownDate}
        card={titoloCard}
        descrizioneHtml={descrizioneHtml}
        sottotitoloDescr={sottotitoloDescr}
        ctaList={ctaNew}
      />
      {scrollPosition > 0 && introSb?.value && (
        <div
          className={classNames("container-fluid px-0", {
            "d-block d-xl-none": scrollPosition === 0,
          })}
        >
          <div className={"sticky-footer" + abTest}>
            <div className="d-flex flex-row justify-content-around align-items-center">
              <div className="d-none d-md-flex flex-row">
                <p className="sticky-footer__intro px-2">{introSb.value}</p>
                <p className="sticky-footer__title px-lg-2">{introSb.content?.titoloSb?.value}</p>
                {!introSb?.content?.titoloPrezzoSb?.value && (
                  <p
                    className="d-none d-lg-block px-1"
                    dangerouslySetInnerHTML={{
                      __html: introSb?.content?.titoloPrezzoSb?.content?.prezzoSb?.value,
                    }}
                  ></p>
                )}
              </div>
              {introSb?.content?.titoloPrezzoSb?.value && (
                <div className="d-none d-xl-flex flex-column px-1">
                  <div className="sticky-footer__price-main">
                    {introSb.content.titoloPrezzoSb.value}
                  </div>
                  {introSb?.content?.titoloPrezzoSb?.content?.prezzoSb?.value && (
                    <div
                      className="sticky-footer__price-descr"
                      dangerouslySetInnerHTML={{
                        __html: introSb.content.titoloPrezzoSb.content.prezzoSb.value,
                      }}
                    ></div>
                  )}
                </div>
              )}
              <div className="d-flex flex-column flex-md-row text-center">
                {((introSb?.content?.ctaSb?.[0]?.value &&
                  introSb?.content?.ctaSb?.[0]?.content?.linkCtaSb?.value &&
                  introSb?.content?.ctaSb?.[0]?.content?.linkCtaSb?.value !== "#") ||
                  introSb?.content?.ctaSb?.[0]?.content?.nameProductSimulatoreFooter?.value) && (
                  <div className="d-flex flex-column flex-md-row text-center px-2">
                    {introSb?.content?.ctaSb?.[0]?.content?.nameProductSimulatoreFooter?.value ? (
                      <button
                        title={introSb.content.ctaSb[0].value}
                        onClick={() => {
                          Cookies.set(
                            "simulatoreNomeProdotto",
                            introSb.content.ctaSb[0].content.nameProductSimulatoreFooter.value
                          );
                          window.open("/simulatore-bolletta");
                        }}
                        className="cta-link sticky-footer__cta w-100 text-center text-nowrap my-1 my-md-0"
                      >
                        {introSb.content.ctaSb[0].value}
                      </button>
                    ) : isFlusso(introSb.content.ctaSb[0].content.linkCtaSb.value) ? (
                      <button
                        title={introSb.content.ctaSb[0].value}
                        className="cta-link sticky-footer__cta w-100 text-center text-nowrap my-1 my-md-0"
                        onClick={() =>
                          startFlusso(
                            getProductName(introSb.content.ctaSb[0].content.linkCtaSb?.value)
                          )
                        }
                      >
                        {introSb.content.ctaSb[0].value}
                      </button>
                    ) : (
                      <Link
                        to={introSb.content.ctaSb[0].content.linkCtaSb.value}
                        title={introSb.content.ctaSb[0].value}
                        className="cta-link sticky-footer__cta w-100 text-center text-nowrap my-1 my-md-0"
                      >
                        {introSb.content.ctaSb[0].value}
                      </Link>
                    )}
                  </div>
                )}
                {((introSb?.content?.ctaSb?.[1]?.value &&
                  introSb?.content?.ctaSb?.[1]?.content?.linkCtaSb?.value &&
                  introSb?.content?.ctaSb?.[1]?.content?.linkCtaSb?.value !== "#") ||
                  introSb?.content?.ctaSb?.[1]?.content?.nameProductSimulatoreFooter?.value) && (
                  <div className="d-flex flex-column flex-md-row text-center px-2">
                    {introSb?.content?.ctaSb?.[1]?.content?.nameProductSimulatoreFooter?.value ? (
                      <button
                        title={introSb.content.ctaSb[1].value}
                        onClick={() => {
                          Cookies.set(
                            "simulatoreNomeProdotto",
                            introSb.content.ctaSb[1].content.nameProductSimulatoreFooter.value
                          );
                          window.open("/simulatore-bolletta");
                        }}
                        className="cta-link sticky-footer__cta w-100 text-center text-nowrap my-1 my-md-0 secondary"
                      >
                        {introSb.content.ctaSb[1].value}
                      </button>
                    ) : isFlusso(introSb.content.ctaSb[1].content.linkCtaSb.value) ? (
                      <button
                        title={introSb.content.ctaSb[1].value}
                        className="cta-link sticky-footer__cta w-100 text-center text-nowrap my-1 my-md-0 secondary"
                        onClick={() =>
                          startFlusso(
                            getProductName(introSb.content.ctaSb[1].content.linkCtaSb?.value)
                          )
                        }
                      >
                        {introSb.content.ctaSb[1].value}
                      </button>
                    ) : (
                      <Link
                        to={introSb.content.ctaSb[1].content.linkCtaSb.value}
                        title={introSb.content.ctaSb[1].value}
                        className="cta-link sticky-footer__cta w-100 text-center text-nowrap my-1 my-md-0 secondary"
                      >
                        {introSb.content.ctaSb[1].value}
                      </Link>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default withPreview(SlideHeader);
export const fragment = graphql`
  fragment SlideHeaderFragment on LiferaySlideHeader {
    liferayFields {
      siteId
      articleId
    }
    model {
      value
    }
    isAbTest {
      value
    }
    slideXl {
      node {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    slide {
      node {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    slideTablet {
      node {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    slideMobile {
      node {
        gatsbyImageData(layout: FULL_WIDTH)
        probeInfo {
          aspectRatio
        }
      }
    }
    titolo {
      value
    }
    descrizione {
      value
    }
    descrizioneMobile {
      value
    }
    textShort {
      value
    }
    imageCut {
      value
    }
    cdownDate {
      value
    }
    cta {
      value
      content {
        ctaColor {
          value
        }
        ctaLink {
          value
        }
        newTab {
          value
        }
        isBold {
          value
        }
      }
    }
    ctaNew {
      content {
        coloreCtaNew {
          value
        }
        coloreCtaNewClick {
          value
        }
        coloreCtaNewHover {
          value
        }
        linkCtaNew {
          value
        }
        nameProductSimulatore {
          value
        }
        tipoCtaNew {
          value
        }
      }
      value
    }
    arrowScroller {
      value
    }
    showProductStars {
      value
      content {
        dataProductId {
          value
        }
      }
    }
    showText {
      value
    }
    introSb {
      value
      content {
        titoloPrezzoSb {
          content {
            prezzoSb {
              value
            }
          }
          value
        }
        ctaSb {
          value
          content {
            coloreCtaClickSb {
              value
            }
            coloreCtaHoverSb {
              value
            }
            coloreCtaSb {
              value
            }
            linkCtaSb {
              value
            }
            tipoCtaSb {
              value
            }
            nameProductSimulatoreFooter {
              value
            }
          }
        }
        titoloSb {
          value
        }
      }
    }
    titoloCard {
      value
      content {
        validita {
          value
        }
        sottotitoloCard {
          value
        }
        commodity {
          value
          content {
            tipoCommodity {
              value
            }
          }
        }
        prodottoCRM {
          dataFineValidita
          corrispettivoEnergia
          corrispettivoF1
          corrispettivoF2
          corrispettivoF3
          componentePrezzoFisso
          spreadF1
          spreadF2
          spreadF3
          corrispettivoBiorariaF1
          corrispettivoBiorariaF23
          quotaCommFissaDomesticoGas
          quotaCommFissaDomesticoLuce
          quotaCommVariabileDomesticoGas
          quotaCommVariabileDomesticoLuce
          quotaPvolSmc
          quotaPvolkWh
          quotaPvolF1kWh
          quotaPvolF2kWh
          quotaPvolF3kWh
          quotaAlfakWh
          quotaAlfaF1kWh
          quotaAlfaF2kWh
          quotaAlfaF3kWh
          spreadGas
          spreadLuce
          spreadLuceLordoPerdite
        }
        genericDescr {
          value
        }
        switchPresent {
          value
          content {
            switchTitle2 {
              value
              content {
                caratt2Ele {
                  value
                  content {
                    valueField2Ele {
                      value
                    }
                    hrSw2Ele {
                      value
                    }
                    isBold2Ele {
                      value
                    }
                    isTooltip2Ele {
                      content {
                        carattTooltipSw2Ele {
                          value
                        }
                      }
                      value
                    }
                  }
                }
                caratt2Gas {
                  value
                  content {
                    valueField2Gas {
                      value
                    }
                    hrSw2Gas {
                      value
                    }
                    isBold2Gas {
                      value
                    }
                    isTooltip2Gas {
                      content {
                        carattTooltipSw2Gas {
                          value
                        }
                      }
                      value
                    }
                  }
                }
                secondTooltip {
                  value
                }
              }
            }
          }
        }
        switchTitle1 {
          value
          content {
            caratt1Ele {
              value
              content {
                valueField1Ele {
                  value
                }
                hrSw1Ele {
                  value
                }
                isBold1Ele {
                  value
                }
                isTooltip1Ele {
                  content {
                    carattTooltipSw1Ele {
                      value
                    }
                  }
                  value
                }
              }
            }
            caratt1Gas {
              value
              content {
                valueField1Gas {
                  value
                }
                hrSw1Gas {
                  value
                }
                isBold1Gas {
                  value
                }
                isTooltip1Gas {
                  content {
                    carattTooltipSw1Gas {
                      value
                    }
                  }
                  value
                }
              }
            }
            firstTooltip {
              value
            }
          }
        }
        noteCard {
          value
        }
      }
    }
    sbScript {
      value
    }
    descrizioneHtml {
      value
    }
    sottotitoloDescr {
      value
    }
  }
`;
