import React, { useEffect } from "react";
import { eventuallyInsertNetreviewsScript } from "../../utility/netreviews";

const NetReviewStars = ({ dataProductId }) => {
  useEffect(() => {
    eventuallyInsertNetreviewsScript();
  }, []);
  return <div className="NETREVIEWS_PRODUCT_STARS" data-product-id={dataProductId}></div>;
};

export default NetReviewStars;
