import classNames from "classnames";
import React from "react";

const Switch = ({ active, toggleFun }) => {
  return (
    <button
      type="button"
      className={classNames("switch", active && "switch--active")}
      onClick={toggleFun}
      aria-label="Switch button"
    >
      <span className="switch__slider switch__slider--round"></span>
    </button>
  );
};

export default Switch;
